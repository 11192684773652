.nav-layout {
    border-top: 3px solid #8dc63f;
    background-color: rgba(0,0,0,0.7);
    padding: 0 95px;
    top:0;
    left:0;
    right: 0;
    z-index: 99998;
height: 40px;
}
.click-layout{
    cursor: pointer;
}
.text-layout{
    color: #8dc63f;
}
.footer-layout{
    background-color: #1b1e21;
}
.logo-layout{
    background-color: white;
    margin-top: 65px;
    height: 100px;
    width: 210px;
    padding: 15px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.logo-layout-footer{
    background-color: white;
    margin-top: 65px;
    height: 100px;
    width: 210px;
    padding: 15px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.card-contact{
    background-color: rgba(242, 242, 242,0.9);
}
.contact-image{
    background-image: url("./../Image/man-with-headphones-facing-computer-monitor-845451.jpg");
    background-size: cover;
    background-position: center;
    height: 615px;
    width: auto;
}
.text-platforms {
    font-size: 20px;
    color: #8dc63f;
    cursor: pointer;
}