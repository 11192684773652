.text-btn-layout {
    cursor: pointer;
}

.div-space{
    background-color: white;
}

.image-wisselende-inventarisatie {
     background-image: url("./../Image/survey_opinion_research_voting_fill_vote_hand_leave_pen-544817.png");
     background-size: cover;
     background-position: center;
     height: 600px;
 }

.div-text-layout-inventarisatie {
    background-color: #f0f0f0;
    height: 150px;
    margin-top: -30px;
}
.div-text-layout-quickscan {
    background-color: #f0f0f0;
    margin-top:160px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: rgba(242, 242, 242,0.9);

}

.div-title-layout-inventarisatie {
    background-color: rgba(0, 0, 0, 0.65);
    height: 75px;
    font-family: "Open Sans", serif;
    font-size: 16px;
    margin-top: 114px;
}
.div-logo-layout-inventarisatie{
    background-image: url("./../Image/EDS - Symbols_Search-16.png");
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 500px;
    margin-top: -425px;
    margin-left: 1000px;
}

/*///////////////////////////*/

.image-wisselende-overzicht {
    background-image: url("./../Image/plant-machine-industry-electricity-energy-manufacturing-1213726-pxhere.com.jpg");
    background-size: cover;
    background-position: center;
    height: 600px;
}

.div-text-layout-overzicht {
    background-color: #f0f0f0;
    height: 150px;
    margin-top: -30px;
}

.div-title-layout-overzicht {
    background-color: rgba(0, 0, 0, 0.65);
    height: 75px;
    font-family: "Open Sans", serif;
    font-size: 16px;
    margin-top: 410px;

}
.div-logo-layout-overzicht{
    background-image: url("./../Image/EDS-Symbols_Checklist_G.png");
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 10px;
    margin-top: -425px;
    margin-left: 1100px;
}
/*///////////////////////////*/

.image-wisselende-meten {
    background-image: url("./../Image/Pressure_img.png");
    background-size: cover;
    background-position: center;
    height: 600px;
}

.div-text-layout-meten {
    background-color: #f0f0f0;
    height: 150px;
    margin-top: -30px;
}

.div-title-layout-meten {
    background-color: rgba(0, 0, 0, 0.65);
    height: 75px;
    font-family: "Open Sans", serif;
    font-size: 16px;
    margin-top: 410px;

}
.div-logo-layout-meten{
    background-image: url("./../Image/Measure_illustrator_G.png");
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 10px;
    margin-top: -425px;
    margin-left: 1100px;
}
/*///////////////////////////*/

.image-wisselende-analyseren {
    background-image: url("./../Image/Analyse.jpg");
    background-size: cover;
    background-position: center;
    height: 600px;
}

.div-text-layout-analyseren {
    background-color: #f0f0f0;
    height: 150px;
    margin-top: -30px;
}

.div-title-layout-analyseren {
    background-color: rgba(0, 0, 0, 0.65);
    height: 75px;
    font-family: "Open Sans", serif;
    font-size: 16px;
    margin-top: 410px;
}
.div-logo-layout-analyseren{
    background-image: url("./../Image/EDS - Symbols_Graph_G.png");
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 10px;
    margin-top: -425px;
    margin-left: 1100px;
}
/*///////////////////////////*/

.image-wisselende-engineering {
    background-image: url("./../Image/engineering2.png");
    background-size: cover;
    background-position: center;
    height: 600px;
}

.div-text-layout-engineering {
    background-color: #f0f0f0;
    height: 150px;
    margin-top: -30px;
}

.div-title-layout-engineering {
    background-color: rgba(0, 0, 0, 0.65);
    height: 75px;
    font-family: "Open Sans", serif;
    font-size: 16px;
    margin-top: 410px;
}
.div-logo-layout-engineering{
    background-image: url("./../Image/EDS - Symbols_Assembly_G.png");
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 10px;
    margin-top: -425px;
    margin-left: 1100px;
}
/*///////////////////////////*/

.image-wisselende-rapportage {
    background-image: url("./../Image/Rapport.jpg");
    background-size: cover;
    background-position: center;
    height: 600px;
}

.div-text-layout-rapportage {
    background-color: #f0f0f0;
    height: 150px;
    margin-top: -30px;
}

.div-title-layout-rapportage {
    background-color: rgba(0, 0, 0, 0.65);
    height: 75px;
    font-family: "Open Sans", serif;
    font-size: 16px;
    margin-top: 410px;
}
.div-logo-layout-rapportage{
    background-image: url("./../Image/EDS-Symbols_Rapportage_G.png");
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 10px;
    margin-top: -425px;
    margin-left: 1100px;
}
.title-kopjes{
    font-family: "Segoe UI";
}
.quickscan-kopje{
    color: #8dc63f;
}
