
.image-layout {
    background-position: center;
    background-size: cover;
    max-width: 100%;
    height: auto;
}

.carousel-layout {
    margin-top: -70px;
    background-color: #1b1e21;
    white-space: initial;
    position: center;
}


.image-layout {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.text-layout {
    /*color: #8dc63f;*/
    color: green;
    font-size: 35px;
}

.aboutus-layout {
    background-color: #1b1e21;
}

.aboutus-image {
    background-image: url("./../Image/building-business-ceiling-empty-209251.jpg");
    background-size: cover;
    background-position: center;
    height: auto;
    width: auto;
}

.title-aboutus-layout {
    font-family: "Segoe UI";
}

.title2-aboutus-layout {
    color: #8dc63f;
    font-size: 35px;
}


.card{
    background-color: rgba(242, 242, 242,0.9);
}